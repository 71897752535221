var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "n-page",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 8 } },
            [
              _c(
                "a-card",
                { staticClass: "subscription-cards" },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { xs: 16, sm: 16, md: 16 } },
                        [
                          _c("a-statistic", {
                            staticStyle: { "margin-right": "50px" },
                            attrs: {
                              title: "Active Members",
                              value: _vm.members,
                              "value-style": { color: "#262626" },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { xs: 8, sm: 8, md: 8 } },
                        [[_c("a-icon", { attrs: { type: "user" } })]],
                        2
                      ),
                      _c("div", { staticClass: "text-dashboard" }, [
                        _vm._v(
                          "\n            Grow your club by inviting new members or just manage your\n            existing members.\n          "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 8 } },
            [
              _c(
                "a-card",
                { staticClass: "subscription-cards" },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { xs: 16, sm: 16, md: 16 } },
                        [
                          _c("a-statistic", {
                            staticStyle: { "margin-right": "50px" },
                            attrs: {
                              title: "Teams",
                              value: _vm.teams,
                              "value-style": { color: "#262626" },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { xs: 8, sm: 8, md: 8 } },
                        [[_c("a-icon", { attrs: { type: "team" } })]],
                        2
                      ),
                      _c("div", { staticClass: "text-dashboard" }, [
                        _vm._v(
                          "\n            Schedule new training sessions, add and remove team members and\n            manage.\n          "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 8 } },
            [
              _c(
                "a-card",
                { staticClass: "subscription-cards" },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { xs: 16, sm: 16, md: 16 } },
                        [
                          _c("a-statistic", {
                            staticStyle: { "margin-right": "50px" },
                            attrs: {
                              title: "Billing",
                              "value-style": { color: "#ffffff" },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { xs: 8, sm: 8, md: 8 } },
                        [[_c("a-icon", { attrs: { type: "money-collect" } })]],
                        2
                      ),
                      _c("div", { staticClass: "text-dashboard" }, [
                        _vm._v(
                          "\n            View a breakdown of your clubs billing history. Track pending\n            payments and view individual transaction invoices.\n          "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } },
            [
              _vm.error_msg
                ? _c("a-alert", {
                    staticClass: "margin_top",
                    attrs: {
                      message: "Error",
                      description: _vm.stripeErrorMsg,
                      type: "error",
                      closable: "",
                      "show-icon": "",
                    },
                    on: { close: _vm.close_alert },
                  })
                : _vm._e(),
              _c("club-subscription-overview", {
                attrs: { cards: _vm.cards },
                on: { fetchData: _vm.listInvoices },
              }),
              _c("div", { staticClass: "add-new-card gx-text-right" }),
              _c(
                "a-card",
                { staticClass: "gx-card-full gx-card-header-full" },
                [
                  _c(
                    "div",
                    { attrs: { slot: "title" }, slot: "title" },
                    [
                      _c(
                        "a-row",
                        { attrs: { type: "flex" } },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 12, sm: 14, md: 12, lg: 14 } },
                            [
                              _vm._v(
                                "\n              Active Cards\n            "
                              ),
                            ]
                          ),
                          _c(
                            "a-col",
                            {
                              staticStyle: { "text-align": "right" },
                              attrs: { xs: 12, sm: 10, md: 12, lg: 10 },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: { margin: "0" },
                                  attrs: {
                                    "inline-block": "",
                                    type: "primary",
                                    size: "small",
                                  },
                                  on: { click: _vm.openNewCardModal },
                                },
                                [
                                  _vm._v(
                                    "\n                Add new Card\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.cards.length > 0
                    ? _c(
                        "div",
                        { staticClass: "credit-cards" },
                        _vm._l(_vm.cards, function (card, index) {
                          return _c("stripe-card-preview", {
                            key: index,
                            attrs: { card: card },
                            on: {
                              remove: _vm.removeCard,
                              setDefault: _vm.setDefault,
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.cardsLoading ? _c("n-section-loading") : _vm._e(),
                  !_vm.cardsLoading && _vm.cards.length === 0
                    ? _c(
                        "div",
                        [
                          _c("n-section-empty", {
                            attrs: {
                              warning:
                                "You have no active cards, please add your first card",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.error_msg
                ? _c("a-alert", {
                    staticClass: "margin_top",
                    attrs: {
                      message: "Error",
                      description: _vm.stripeErrorMsg,
                      type: "error",
                      closable: "",
                      "show-icon": "",
                    },
                    on: { close: _vm.close_alert },
                  })
                : _vm._e(),
              _c("div", { staticClass: "add-new-card gx-text-right" }),
              _c(
                "a-card",
                { staticClass: "gx-card-full gx-card-header-full" },
                [
                  _c(
                    "div",
                    { attrs: { slot: "title" }, slot: "title" },
                    [
                      _c(
                        "a-row",
                        { attrs: { type: "flex" } },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 12, sm: 14, md: 12, lg: 14 } },
                            [
                              _vm._v(
                                "\n              Active Bank Detail\n            "
                              ),
                            ]
                          ),
                          _c(
                            "a-col",
                            {
                              staticStyle: { "text-align": "right" },
                              attrs: { xs: 12, sm: 10, md: 12, lg: 10 },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: { margin: "0" },
                                  attrs: {
                                    "inline-block": "",
                                    type: "primary",
                                    size: "small",
                                  },
                                  on: { click: _vm.redirectStripeAccount },
                                },
                                [
                                  _vm._v(
                                    "\n                Add Bank Account\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.bank.length > 0
                    ? _c(
                        "div",
                        { staticClass: "credit-cards" },
                        _vm._l(_vm.bank, function (bbank, index) {
                          return _c("bank-card-preview", {
                            key: index,
                            attrs: { card: bbank },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.bankLoading ? _c("n-section-loading") : _vm._e(),
                  !_vm.bankLoading && _vm.bank.length === 0
                    ? _c(
                        "div",
                        [
                          _c("n-section-empty", {
                            attrs: { warning: "You have no bank account yet" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } },
            [
              _c(
                "a-card",
                { staticClass: "gx-card-table-full" },
                [
                  _c("stripe-billing-history-table", {
                    attrs: {
                      billing: _vm.billing,
                      loading: _vm.billingLoading,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("stripe-new-card-modal", {
        attrs: { visible: _vm.newCardModalVisible },
        on: { token: _vm.saveCard, close: _vm.closeNewCardModalVisible },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
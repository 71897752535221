var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { type: "flex" } },
    [
      _c(
        "a-col",
        {
          staticClass: "card-number",
          attrs: { xs: 24, sm: 24, md: 24, lg: 14 },
        },
        [
          _c("a-icon", { attrs: { type: "credit-card" } }),
          _vm._v(
            "\n    **** **** **** " + _vm._s(_vm.card.lastFourDigit) + "\n    "
          ),
          _c("br"),
          _vm._v("\n    " + _vm._s(_vm.card.name) + "\n  "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }